import React, { useState, useEffect } from 'react';
import './Gallery.css';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import { createHash } from 'crypto';
import { galleries } from "../../portfolio";

function generateHash(fileName: string): string {
  const hash = createHash('md5');
  hash.update(fileName);
  const fullHash = hash.digest('hex');
  const trimmedHash = fullHash.substring(0, 7); // 長すぎるので7桁に切り詰める
  return trimmedHash;
}

function ImageGallery({isHeaderVisible, setIsHeaderVisible, images}: GalleryProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const idParam = queryParams.get('lightbox');
        if (idParam) {
            const index = images.findIndex((image) => image.id === idParam);
        if (index !== -1) {
            setPhotoIndex(index);
            setIsOpen(true);
            setIsHeaderVisible(false); // 直接ページに飛んできたときにヘッダーを非表示にする
        }
        }
    }, [images, setIsHeaderVisible]);

    const openLightbox = (index: number) => {
        setPhotoIndex(index);
        setIsOpen(true);
        if (isHeaderVisible) {
            setIsHeaderVisible(false); // モーダルが開かれた時にヘッダーを非表示にする
        }
        window.history.pushState(null, '', `?lightbox=${images[index].id}`);
    };

    const closeLightbox = () => {
        setIsOpen(false);
        if (!isHeaderVisible) {
            setIsHeaderVisible(true); // モーダルが閉じられた時にヘッダーを表示する
        }
        window.history.pushState(null, '', window.location.pathname);
    };

    const switchLightbox = (index: number) => {
        window.history.pushState(null, '', `?lightbox=${images[index].id}`);
    }

    return (
        <div>
            <div className='gallery-content'>
                {images.map((image, index) => (
                    <img
                        key={image.id}
                        src={image.url}
                        alt={image.caption}
                        onClick={() => openLightbox(index)}
                    />
                ))}
            </div>
            {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex].url}
                    nextSrc={images[(photoIndex + 1) % images.length].url}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length].url}
                    onCloseRequest={closeLightbox}
                    onMovePrevRequest={() => {
                        switchLightbox((photoIndex + images.length - 1) % images.length);
                        setPhotoIndex((photoIndex + images.length - 1) % images.length);
                    }}
                    onMoveNextRequest={() => {
                        switchLightbox((photoIndex + 1) % images.length);
                        setPhotoIndex((photoIndex + 1) % images.length);
                    }}
                    imageCaption={images[photoIndex].caption}
                />
            )}
        </div>
    );
};

function Gallery({isHeaderVisible, setIsHeaderVisible}: Props) {
    const images = galleries.lists.map((list, i) => {
        return {
            id: `dataItem-${generateHash(list.img)}`, url: require(`../../assets/${list.img}`), caption: list.caption
    }})

    return(
        <section>
                <section className="section--gallery" id="gallery">
                    <div className="inner">
                            <div className="gallery-title">
                                {galleries.title}
                            </div>
                            <ImageGallery isHeaderVisible={isHeaderVisible} setIsHeaderVisible={setIsHeaderVisible} images={images} />
                    </div>
                </section>
        </section>
    )
}

interface Props {
    isHeaderVisible: boolean,
    setIsHeaderVisible: React.Dispatch<React.SetStateAction<boolean>>
}

interface galleryInfo {
    id: string,
    url: string,
    caption: string
}

interface GalleryProps {
    isHeaderVisible: boolean,
    setIsHeaderVisible: React.Dispatch<React.SetStateAction<boolean>>
    images: galleryInfo[];
}

export default Gallery;
