import React from "react";
import './RSSFeedCard.css';
import Moment from 'react-moment';

function RSSFeedCard({rssfeed}: Props){
    const Fade: any = require('react-reveal/Fade');
    return(
        <Fade bottom duration={800}>
        <div className="rssfeed-card">
            <div className="rssfeed-card-title">
                {rssfeed.title}
            </div>
            <div className="rssfeed-card-pubDate">
                <Moment format="YYYY/MM/DD">{rssfeed.pubDate}</Moment>
            </div>
            <div className="rssfeed-card-link">
                <a href={rssfeed.link} target="_blank" rel="noopener noreferrer">Read More</a>
            </div>
        </div>
        </Fade>
    );
}

interface Props {
    rssfeed: {
        title: string,
        pubDate: string,
        link: string
    }
}
export default RSSFeedCard;
