import React from 'react';
import { staff } from '../../portfolio';
import StaffCard from '../StaffCard/StaffCard';
import './Staff.css';

function Staff() {
    return (
        <section className="section--staff" id="staff">
            <div className="inner">

                <div className="staff-title">
                    <p>{staff.title}</p>
                </div>

                <div className="staff-cards">
                    {staff.lists.map((list, i) => {
                        return <StaffCard staff={list} key={i} />
                    })}
                </div>

            </div>
        </section>
    );
}
export default Staff;
