import React from 'react';
import './WorkCard.css';

function simpleEncrypt(input: string): string {
  return Buffer.from(input, 'utf-8').toString('base64');
}

function WorkCard({ works }: Props) {
    const Fade: any = require('react-reveal/Fade');
    const id = simpleEncrypt(works.img);
    return(
        <Fade bottom duration={800}>
        <div className="work-card">
            <a href={`item?id=${id}`} className="work-link">
            <div className="work-card-img">
                <img src={require(`../../assets/${works.img}`)} alt={works.alt}/>
            </div>
            <div className="work-card-title">
                <p>{works.title}</p>
            </div>
            <div className="work-card-desc">
                <p>{works.desc}</p>
            </div>
            <div className="work-card-desc">
                <p>Published: {works.published}</p>
            </div>
            <div className="work-card-desc">
                <p>Author: {works.author}</p>
            </div>
            <div className="work-card-desc">
		        <div className="social-media-links">
			        {works.shop && <a href={works.shop} className="icon-button shop" target="_blank" rel="noopener noreferrer">
				    <i className="fa-solid fa-cart-shopping"></i>
			        </a>}
			        {works.link && <a href={works.link} className="icon-button link" target="_blank" rel="noopener noreferrer">
				    <i className="fa-solid fa-arrow-up-right-from-square"></i>
			        </a>}
		        </div>
            </div>
            </a>
        </div>
        </Fade>
    );
}

interface Props {
    works: {
        img: string,
        alt: string,
        title: string,
        desc: string,
        published: string,
        author: string,
        shop: string,
        link: string
    }
}
export default WorkCard;
