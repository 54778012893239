import React, { useState, useEffect } from 'react';
import './RSSFeedBox.css';
import RSSFeedCard from '../RSSFeedCard/RSSFeedCard';

interface FeedItem {
  title: string;
  link: string;
  pubDate: string;
  // Add other necessary fields from the API response
}

function RSSFeed({ feedInfo }: Props) {
    const Fade: any = require("react-reveal/Fade");
    const [feedItems, setFeedItems] = useState<FeedItem[]>([]);

    useEffect(() => {
        const fetchData = async () => {
        try {
            const response = await fetch(feedInfo.url);
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            if (Array.isArray(responseData.items)) {
                const formattedItems: FeedItem[] = responseData.items.map((item: any) => ({
                    title: item.title ?? '',
                    link: item.link ?? '',
                    pubDate: item.pubDate ?? '',
                    // Map other necessary fields from the API response
            }));
            setFeedItems(formattedItems);
            }
        } catch (error) {
            console.error('Error fetching feed from API:', error);
        }
        };

        fetchData();
    }, [feedInfo.url]);

  return (
    <section>
      <Fade bottom duration={800}>
        <section className='section--rssfeed-box'>
          <div className='inner'>
            <div className='wrapper'>
              <div className='rssfeed-box-title'>{feedInfo.title}</div>
            </div>
            <div className='rssfeed-box-content'>
              {console.log(feedItems)}
              {feedItems.map((feed, i) => {
                return <RSSFeedCard rssfeed={feed} key={i} />
              })}
            </div>
          </div>
        </section>
      </Fade>
    </section>
  );
};

interface Props {
    feedInfo: {
        title: string,
        url: string
    }
}

export default RSSFeed;
