import React from 'react';
import { useLocation } from "react-router-dom";
import './WorkPage.css';
import Works from '../../assets/data/works.json'

function simpleDecrypt(encrypted: string): string {
  return Buffer.from(encrypted, 'base64').toString('utf-8');
}

function decodeWork(id: string) {
    const filePath = simpleDecrypt(id)
    const match_work = Works.filter((work: Props, index: number) => {
        return work.img === filePath;
    });
    if (match_work.length !== 1) {
        return {
            img: "img/no_image.png",
            alt: "",
            title: "",
            desc: "このようなWorkは存在しません。すみませんが、トップページからたどり直してください。",
            published: "",
            author: "",
            shop: "",
            link: ""
        }
    }
    return match_work[0]
}

function WorkPage() {
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const id = String(query.get('id'))
    const work = decodeWork(id)
    const Fade: any = require('react-reveal/Fade');
    return(
        <Fade bottom duration={800}>
        <div className="work-page">
            <div className="work-page-img">
                <img src={require(`../../assets/${work.img}`)} alt={work.alt}/>
            </div>
            <div className="work-page-title">
                <p>{work.title}</p>
            </div>
            <div className="work-page-desc">
                <p>{work.desc}</p>
            </div>
            <div className="work-page-desc">
                <p>Published: {work.published}</p>
            </div>
            <div className="work-page-desc">
                <p>Author: {work.author}</p>
            </div>
            <div className="work-page-desc">
		        <div className="social-media-links">
			        {work.shop && <a href={work.shop} className="icon-button shop" target="_blank" rel="noopener noreferrer">
				    <i className="fa-solid fa-cart-shopping"></i>
			        </a>}
			        {work.link && <a href={work.link} className="icon-button link" target="_blank" rel="noopener noreferrer">
				    <i className="fa-solid fa-arrow-up-right-from-square"></i>
			        </a>}
		        </div>
            </div>
        </div>
        </Fade>
    );
}

interface Props {
        img: string,
        alt: string,
        title: string,
        desc: string,
        published: string,
        author: string,
        shop: string,
        link: string
}

export default WorkPage;
