import React, { useState } from 'react';
import Header from './Header/Header';
import Hello from './Hello/Hello';
import Skill from './News/News';
import Top from './topButton/Top';
import Work from './Works/Works';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import Staff from './Staff/Staff';
import { header, greeting, news, staff, works, contactInfo, twitter, galleries, rssFeeds } from '../portfolio';
import Twitter from './Twitter/Twitter';
import Gallery from './Gallery/Gallery';
import HeaderIllust from './HeaderIllust/HeaderIllust';
import RSSFeed from './RSSFeed/RSSFeed';

function Main() {
	const [isHeaderVisible, setIsHeaderVisible] = useState(header.view);

	return (
		<div className="root">
			{isHeaderVisible && <Header />}
			<HeaderIllust/>
			{greeting.view && <Hello />}
			{news.view && <Skill />}
			{staff.view && <Staff />}
			{works.view && <Work />}
			{galleries.view && <Gallery isHeaderVisible={isHeaderVisible} setIsHeaderVisible={setIsHeaderVisible} />}
			{contactInfo.view && <Contact />}
			{twitter.view && <Twitter />}
			{rssFeeds.view && <RSSFeed />}
			<Footer />
			<Top />
		</div>
	);
}
export default Main;
