import React from 'react';
import './News.css';
import { news } from '../../portfolio';
import NewsCard from '../NewsCard/NewsCard';

function News() {
    const Fade: any = require("react-reveal/Fade");
    //                             <p key={i}>✔ {desc.date} {desc.title}</p>

    return(
        <section className="section--news" id="news">
            <div className="inner">

                <Fade left duration={800}>
                <div className="wrapper">
                    <div className="news-title">
                        <p>{news.title}</p>
                    </div>
                    <div className="news-subtitle">
                        <p>{news.subTitle}</p>
                    </div>
                    <div className="news-desc">
                        {news.describeNews.map((desc, i) => (
                            <NewsCard news={desc} key={i}/>
                        ))}
                    </div>
                </div>
                </Fade>

                <Fade right duration={800}>
                </Fade>

            </div>
        </section>
    );
}
export default News;
