import React from 'react';
import { Timeline } from 'react-twitter-widgets';
import './Twitter.css';

const TwitterTimeline = () => {
    return (
        <Timeline
            dataSource={{
                sourceType: 'profile',
                screenName: 'moemoecompany'
            }}
            options={{
                username: 'moemoecompany',
                width: '100%',
                height: '500'
            }}
            onLoad={() => console.log('Timeline is loaded!')}
            />
    )
}

function Twitter() {
    const Fade: any = require('react-reveal/Fade');
    return (
        <Fade bottom duration={800}>
            <div className="twitter-main">
                <TwitterTimeline />
            </div>
        </Fade>
    );
}
export default Twitter;
