import React from 'react';
import './StaffCard.css';

function StaffCard({ staff }: Props) {
    const Fade = require('react-reveal/Fade');
    return (
        <Fade duration={800} bottom>
        <div className="staffcard-main">
            <div className="inner">
                    
                <div className="staffcard-role">
                    {staff.name}
                </div>
                <div className="staffcard-company">
                    {staff.roleshort}
                </div>
                <div className="staffcard-date">
                    {staff.role}
                </div>
                <div className="staffcard-icon">
                    <img src={require(`../../assets/${staff.icon}`)} alt="profile" />
                </div>
			    {staff.twitter && <a href={staff.twitter} className="icon-button twitter" target="_blank" rel="noopener noreferrer">
				    <i className="fa-brands fa-x-twitter"></i>
			    </a>}
			    {staff.homepage && <a href={staff.homepage} className="icon-button homepage" target="_blank" rel="noopener noreferrer">
				    <i className="fa-solid fa-house"></i>
			    </a>}
			    {staff.pixiv && <a href={staff.pixiv} className="icon-button pixiv" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-pixiv"></i>
			    </a>}
            </div>
        </div>
        </Fade>
    );
}
interface Props {
    staff : {
        name: string,
        roleshort: string,
        role: string,
        icon: string,
        twitter: string,
        homepage: string,
        pixiv: string
    }
}
export default StaffCard;
