import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import WorkPage from "./WorkPage/WorkPage";

function Sub() {
    return(
        <div className="root">
            <Header />
            <WorkPage />
            <Footer />
        </div>
    )
}
export default Sub;
