import Staff from "./assets/data/staff.json";
import Works from "./assets/data/works.json";
import Galleries from "./assets/data/galleries.json";
import Feeds from "./assets/data/feeds.json";
import News from "./assets/data/news.json";

export const header = {
	view: true
}

export const greeting = {
	title: 'ようこそ萌え萌えカンパニーへ',
	subTitle: '今日も求める萌え！〜お絵描き練習会体験募集中〜希望者はメール/DMまでお待ちしています',
	role: "萌えの探求者たちの集い",
	shopLink: "https://moemoecompany.booth.pm",
	view: true
};

export const socialMediaLinks = {
	email: 'moemoekanpani@gmail.com',
	twitter: "https://twitter.com/moemoecompany"
};

export const news = {
	title: "News",
	subTitle: "Recent activities",
	describeNews: News,
	view: true
};

export const staff = {
	title: "Staff",
	lists: Staff,
	view: true
};

export const works = {
	title: "Works",
	subTitle: "",
	lists: Works,
	view: true
};

export const galleries = {
	title: "Gallery",
	lists: Galleries,
	view: true
};

export const contactInfo = {
	title: "Contact Us",
	subTitle: "ご依頼はこちらまでお願いします",
	introduce: "https://docs.google.com/forms/d/e/1FAIpQLScD4XvoKr-Xyf5qgYzNem-RX5mvnjQdZ0OR0zRiDgOygqv0Iw/viewform",
	view: true
};

export const twitter = {
	view: true
};

export const rssFeeds = {
	title: "Feeds",
	feeds: Feeds,
	view: false
};
