import React from 'react';
import './Works.css';
import { works } from '../../portfolio';
import WorkCard from '../WorkCard/WorkCard'

function Work() {
    return (
        <section className="section--work" id="works">
            <div className="inner">

                <div className="work-title">
                    <p>{works.title}</p>
                </div>

                <div className="work-subtitle">
                    <p>{works.subTitle}</p>
                </div>

                <div className="work-content">
                    {works.lists.map((list, i) => {
                        return <WorkCard works={list} key={i} />
                    })}
                </div>

            </div>
        </section>
    );
}
export default Work;
