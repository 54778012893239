import React from 'react';
import './HeaderIllust.css';

function HeaderIllust() {
	const Fade: any = require("react-reveal/Fade");
    return (
        <Fade bottom duration={800}>
            <div className='header-illust'/>
        </Fade>
    );
};

export default HeaderIllust;
