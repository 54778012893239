import React from 'react';
import './Header.css';
import { news, staff, works, contactInfo } from '../../portfolio';

function Header() {

	const onClickMenu = function(): void {
		if (window.innerWidth < 768) {
			let navMenu = document.getElementById("toggle-nav");
			if (!navMenu) throw new Error("HTML Error!");
			if (navMenu.className === "nav-menu") {
				navMenu.className += " responsive";
			} else {
				navMenu.className = "nav-menu";
			}
		}
	}

	return (
		<header className="header header--section">
			<div className="inner clearfix">

				<div className="header-logo">
					<a href="/"><img src={require("../../assets/img/moemoecompany.png")} alt="profile" /></a>
				</div>

				<div className="icon">
					<a href="#toggle-nav" className="icon fas fa-bars" onClick={onClickMenu}> </a>
				</div>

				<ul className="nav-menu" id="toggle-nav">
					{news.view && <li className="nav-list">
						<a href="#news" onClick={onClickMenu}>
							News
						</a>
					</li>}
					{staff.view && <li className="nav-list" onClick={onClickMenu}>
						<a href="#staff">
							Staff
						</a>
					</li>}
					{works.view && <li className="nav-list" onClick={onClickMenu}>
						<a href="#works">
							Works
						</a>
					</li>}
					{contactInfo.view && <li className="nav-list" onClick={onClickMenu}>
						<a href="#gallery">
							Gallery
						</a>
					</li>}
					{contactInfo.view && <li className="nav-list" onClick={onClickMenu}>
						<a href="#contact">
							Contact Us
						</a>
					</li>}
				</ul>

			</div>
		</header>
	);
}
export default Header;
