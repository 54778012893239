import React from "react";
import './NewsCard.css';

function NewsCard({news}: Props){
    const Fade: any = require('react-reveal/Fade');
    return(
        <Fade bottom duration={800}>
        <div className="news-card">
            <div className="news-card-title">
                <p>✔ {news.date} {news.title}</p>
            </div>
        </div>
        </Fade>
    );
}

interface Props {
    news: {
        title: string,
        date: string
    }
}
export default NewsCard;
