import React from 'react';
import './RSSFeed.css';
import RSSFeedBox from '../RSSFeedBox/RSSFeedBox';
import { rssFeeds } from '../../portfolio';

function RSSFeed() {
  const Fade: any = require("react-reveal/Fade");

  return (
    <section>
      <Fade bottom duration={800}>
        <section className='section--rssfeed'>
          <div className='inner'>
            <div className='wrapper'>
              <div className='rssfeed-title'>{rssFeeds.title}</div>
            </div>
            <div className='rssfeed-content'>
              {rssFeeds.feeds.map((feed, i) => {
                return <RSSFeedBox feedInfo={feed}/>
              })}
            </div>
          </div>
        </section>
      </Fade>
    </section>
  );
};

export default RSSFeed;
